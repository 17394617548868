import React, { useState, useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import debounce from "lodash.debounce";
import { TextInput } from "../FormInputs/TextInput";
import { LocalGovtAssociation } from "../FormInputs/LocalGovtAssociation";
import { LocationFormStyled } from "./styled";
import { Location } from "../FormInputs/Location";
import {
  submitCompanyLocationForm,
  deleteCompanyAddress,
} from "actions/companyActions";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { FlashError } from "../FormInputs/FlashError";
import NoBorderButton from "components/RFQ/NoBorderButton/NoBorderButton";
import { Submit } from "components/FormInputs/Submit";
import {showToast as showToastFunction} from "../../utils/helpers";

const AutoSave = ({ debounceMs, setShowToast }) => {
  const formik = useFormikContext();
  setShowToast(false);
  // eslint-disable-next-line
  const debouncedSubmit = React.useCallback(
    debounce(() => formik.submitForm(), debounceMs),
    [formik.submitForm]
  );

  useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return true;
};
export const CompanyLocationFormComponent = ({
  company,
  viewOnly,
  btnOfficeLocation,
  ...props
}) => {
  const initialValues = props.initialValues;
  const [showToast, setShowToast] = useState(false);
  const noOfAddresses = parseInt(company.locations.length);
  const [forms, setForms] = useState(noOfAddresses > 0 ? noOfAddresses - 1 : 0);
  const [sameState, setSameState] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  // Fill rest of the arrays with empty data. Temporary fix for following known issue with the library.
  // Ref: https://github.com/jquense/yup/issues/676
  const autoFillInitialValues = (initialValues) => {
    for (let i = 0; i < forms; i++) {
      for (let key in initialValues) {
        for (let j = initialValues[key].length + 1; j <= forms; j++) {
          initialValues[key].push(undefined);
        }
      }
    }

    return initialValues;
  };

  const handleAddressDelete = async (companyId, addressId) => {
    if (!addressId) {
      setSameState(true);
      setForms(forms - 1);
    } else {
      try {
        const res = await props.deleteCompanyAddress(companyId, addressId);
        if (res) {
          setForms(forms - 1);
          showToastFunction("Address has been deleted", "Success", true);
        } else showToastFunction("Unable to delete Address", "Error");
      } catch (error) {
        showToastFunction("Unable to delete Address: " + error, "Error");
      }
    }
  };

  const getErrors = (n, msg) => {
    const errObj = { 0: msg };
    for (let i = 1; i < n; i++) {
      errObj[i] = msg;
    }
    return errObj;
  };

  const checkForEmptyAddresses = (values) => {
    let isRequired = false;
    for (let a = 0; a <= forms; a++) {
      if (
        !values?.address[a]?.value ||
        values?.address[a]?.value?.trim() === ""
      ) {
        isRequired = true;
        break;
      }
      if (!values?.city[a] || values?.city[a]?.trim() === "") {
        isRequired = true;
        break;
      }
      if (!values?.local_govt_association_id[a]) {
        isRequired = true;
        break;
      }
      if (!values?.postcode[a]) {
        isRequired = true;
        break;
      }
      if (!values?.state[a] || values?.state[a]?.trim() === "") {
        isRequired = true;
        break;
      }
    }
    setDisableButton(isRequired);
  };

  return company !== undefined ? (
    <>
      <LocationFormStyled>
        <Formik
          innerRef={props?.formikRef}
          enableReinitialize
          initialValues={autoFillInitialValues(initialValues)}
          validationSchema={Yup.object({
            state: Yup.array().test(
              "same state",
              getErrors(forms + 1, "Address must be from same state"),
              (value) => {
                return value.every(
                  (v) => v === value[0] && v != null && v !== ""
                );
              }
            ),
            address: Yup.array().of(
              Yup.object().shape({
                value: Yup.string().typeError("Address is required").required(),
              })
            ),
            city: Yup.array().of(
              Yup.string().typeError("City is required").required()
            ),
            postcode: Yup.array().of(
              Yup.number().typeError("Postcode must be numbers").required()
            ),
            local_govt_association_id: Yup.array().of(
              Yup.number()
                .typeError("Local Government Area is required")
                .required()
            ),
          })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            if (
              values["address"].length === forms + 1 &&
              values["postcode"].length === forms + 1 &&
              values["city"].length === forms + 1 &&
              values["state"].length === forms + 1 &&
              values["local_govt_association_id"].length === forms + 1
            ) {
              try {
                if (!values.local_govt_association_id)
                  delete values.local_govt_association_id;
                await props.submitCompanyLocationForm(
                  values.company_id,
                  values
                );
                showToast && showToastFunction("Data Updated Successfully", "Success", true);
                setSameState(true);
              } catch (e) {
                const error = await e.response.json();
                showToastFunction(error.message, "Error");
                error.errors !== undefined && setErrors(error.errors);
              }
            } else {
              setSameState(true);
            }
            setSubmitting(false);
          }}
          validate={(values) => {
            checkForEmptyAddresses(values);
          }}
          validateOnChange={true}
        >
          {(props) => {
            const {
              setFieldValue,
              isSubmitting,
              values,
              setErrors,
              isValid,
              dirty,
            } = props;
            const onSubmitValidationError = (e) => {
              if ("state" in e.errors) {
                setSameState(false);
              } else {
                setSameState(true);
              }
            };
            return (
              <Form>
                {forms + 1 === company.locations.length &&
                  !isSubmitting &&
                  JSON.stringify(initialValues) !== JSON.stringify(values) && (
                    <AutoSave setShowToast={setShowToast} debounceMs={850} />
                  )}

                <div className="row">
                  <div className="mb-5">
                    <div className="font-roboto font-semibold text-text-primary-900 text-[18px]">
                      Your Primary Office Address
                    </div>
                    <p className="font-roboto text-sm text-text-tertiary-600 mt-2">
                      Your office location
                    </p>
                  </div>
                  <div className="col xl-12">
                    <div className="row">
                      <Location
                        label="Address"
                        required={!viewOnly}
                        name="address[0]"
                        setFieldValue={setFieldValue}
                        initialValue={initialValues.address[0]}
                        className="col xl-12 !mb-6"
                        disabled={viewOnly}
                        errorBottom={"-3px"}
                        rfq={true}
                      />
                      <div className="col xl-12">
                        <div className="row">
                          <div className="flex justify-between items-center gap-6 w-full">
                            <TextInput
                              label="Post Code"
                              required={!viewOnly}
                              name="postcode[0]"
                              className="flex-1"
                              flexGrow="1"
                              disabled={viewOnly}
                            />
                            <TextInput
                              label="City / Suburb"
                              required={!viewOnly}
                              name="city[0]"
                              className="flex-1"
                              flexGrow="1"
                              disabled={viewOnly}
                            />
                          </div>

                          <div className="flex justify-between items-center gap-6 w-full">
                            <TextInput
                              label="State"
                              required={!viewOnly}
                              name="state[0]"
                              className="flex-1"
                              flexGrow="1"
                              disabled={viewOnly}
                            />
                            <LocalGovtAssociation
                              name="local_govt_association_id[0]"
                              profileType={values.profile_type}
                              value={values.local_govt_association_id[0]}
                              className="flex-1"
                              flexGrow="1"
                              required={!viewOnly}
                              disabled={viewOnly}
                              state={values.state[0]}
                              errorBottom={"-3px"}
                              noOptionsMessage="Please select State first"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {[...Array(forms)].map((formNo, index) => {
                  return (
                    <div key={index} className="row">
                      <div className="col xl-12">
                        <div className="form-divider mt-2"></div>
                      </div>
                      {index === 0 && (
                        <div className="mb-5">
                          <div className="font-roboto font-semibold text-text-primary-900 text-[18px]">
                            Your Secondary Office Address
                          </div>
                          <p className="font-roboto text-sm text-text-tertiary-600 mt-2">
                            Your office locations
                          </p>
                        </div>
                      )}
                      <div className="col xl-12">
                        <div className="row">
                          <Location
                            label="Address"
                            required={!viewOnly}
                            name={`address[${index + 1}]`}
                            setFieldValue={setFieldValue}
                            initialValue={initialValues.address[index + 1]}
                            className="col xl-12"
                            disabled={viewOnly}
                          />
                          <div className="col xl-12">
                            <div className="row">
                              <div className="flex justify-between items-center gap-6 w-full">
                                <TextInput
                                  label="Post Code"
                                  required={!viewOnly}
                                  maxLength="4"
                                  name={`postcode[${index + 1}]`}
                                  className="flex-1"
                                  flexGrow="1"
                                  disabled={viewOnly}
                                />
                                <TextInput
                                  label="City / Suburb"
                                  required={!viewOnly}
                                  name={`city[${index + 1}]`}
                                  className="flex-1"
                                  flexGrow="1"
                                  disabled={viewOnly}
                                />
                              </div>
                              <div className="flex justify-between items-center gap-6 w-full">
                                <TextInput
                                  label="State"
                                  required={!viewOnly}
                                  name={`state[${index + 1}]`}
                                  className="flex-1"
                                  flexGrow="1"
                                  disabled={viewOnly}
                                />
                                <LocalGovtAssociation
                                  name={`local_govt_association_id[${index + 1}]`}
                                  profileType={values.profile_type}
                                  value={
                                    values.local_govt_association_id[index + 1]
                                  }
                                  className="flex-1"
                                  flexGrow="1"
                                  required={!viewOnly}
                                  disabled={viewOnly}
                                  state={values.state[index + 1]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {!viewOnly && (
                        <div className="col xl-12 delete-address duration-300 mb-3">
                          <button
                            title="Delete Address"
                            className="font-roboto text-sm font-semibold delete-btn text-utility-error-700"
                            type="button"
                            style={{ transition: "all 0.4s ease-in-out" }}
                            disabled={props.isRequesting}
                            onClick={() => {
                              // remove values as well
                              values.address.splice(index + 1, 1);
                              values.postcode.splice(index + 1, 1);
                              values.city.splice(index + 1, 1);
                              values.state.splice(index + 1, 1);
                              values.local_govt_association_id.splice(
                                index + 1,
                                1
                              );
                              setErrors({});
                              handleAddressDelete(
                                initialValues.company_id,
                                initialValues.id[index + 1]
                              );
                            }}
                          >
                            Delete Address
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
                <TextInput
                  name="company_id"
                  value={initialValues.company_id}
                  type="hidden"
                />
                {!viewOnly && (
                  <div className="add-more-container flex w-full">
                    {!sameState && (
                      <div className="mt-2 mb-2">
                        <FlashError
                          heading={"Location Error"}
                          text={
                            "You are trying to add a address from a different state. Each address must be from same state."
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
                {!viewOnly && (
                  <div className="flex justify-between items-center">
                    <div className="flex-1">
                      <NoBorderButton
                        iconPosition="left"
                        icon="plus-circle"
                        iconStyle={{
                          width: "20px",
                          height: "20px",
                          color: "#475467",
                        }}
                        iconClassName="text-text-tertiary-600 text-lg"
                        onClick={() => setForms(forms + 1)}
                        text="Add New Address"
                        className="bg-white text-text-tertiary-600 text-[14px]"
                      />
                    </div>
                    <Submit
                      type="submit"
                      onClick={() => {
                        setShowToast(true);
                      }}
                      isSubmitting={isSubmitting}
                      spinner
                      text="Save Changes"
                      submittingText="Saving..."
                      disabled={!dirty || !isValid || disableButton}
                      className="!h-[50px]"
                    />
                  </div>
                )}
                <OnSubmitValidationError callback={onSubmitValidationError} />
              </Form>
            );
          }}
        </Formik>
      </LocationFormStyled>
    </>
  ) : (
    <Skeleton count={5} duration={0.5} />
  );
};

const mapStateToProps = (state) => {
  const company = state.search.activeCompany;
  const locations = state.search.activeCompany.locations;
  return {
    isRequesting: state.companyProfile.isRequesting,
    company: company,
    initialValues: {
      address:
        locations.map((loc) => {
          return {
            value: loc.address,
            longitude: loc.longitude,
            latitude: loc.latitude,
          };
        }) || [],
      local_govt_association_id:
        locations.map((loc) => loc.local_govt_association_id) || [],
      company_id: company.id || "",
      postcode: locations.map((loc) => loc.postcode) || [],
      city: locations.map((loc) => loc.city) || [],
      state: locations.map((loc) => loc.state) || [],
      id: locations.map((loc) => loc.id) || [],
      profile_type: company.profile_type.id,
    },
  };
};

export const CompanyLocationForm = connect(mapStateToProps, {
  submitCompanyLocationForm,
  deleteCompanyAddress,
})(CompanyLocationFormComponent);